import TopBusinessQuotesWrapper from "@components/layouts/topBusinessQuotesWrapper";
import MainBanner from "@components/topbusinessquotes/mainBanner";
import WhyTopBusinessQuotes from "@components/topbusinessquotes/whyTopBusinessQuotes";
import { getPageProps } from "@services/initial-calls";
import { GetServerSideProps } from "next";
import React, { ReactElement } from "react";

export default function TopBusinessQuotesHome(): ReactElement {
    return (
        <TopBusinessQuotesWrapper>
            <MainBanner />
            <WhyTopBusinessQuotes />
        </TopBusinessQuotesWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
